<template>
  <div class="eatInOrder">
    <div class="timeChoiceDiv flexbox flex-lr flex-align-center">
        <div class="timeAndBtn flexbox flex-lr flex-align-center mr20">
          <el-input v-model="form.orderNumber" placeholder="请输入订单编号" style="width:160px;" clearable></el-input>
          <el-select v-model="form.payStatus" placeholder="支付状态" clearable class="ml20">
            <el-option v-for="item in payStatusList" :key="item.value"
                       :label="item.label" :value="item.value">
            </el-option>
          </el-select>
          <el-select v-model="form.spreadId" placeholder="请选择归属分公司" clearable filterable remote class="ml20"
            :loading="loading" :remote-method="searchBranchOffice" @change="spreadIdChange">
            <el-option v-for="item in branchOfficeList" :key="item.value"
                       :label="item.label" :value="item.value">
            </el-option>
          </el-select>
          <el-select v-model="form.agentId" placeholder="请选择代理商" clearable filterable remote class="ml20"
          :loading="loading" :remote-method="searchAgent" @change="agentSidChange">
            <el-option v-for="item in agentList" :key="item.value"
                       :label="item.label" :value="item.value">
            </el-option>
          </el-select>
          <el-select v-model="form.shopId" placeholder="请选择商户" clearable filterable remote class="ml20"
          :loading="loading" :remote-method="searchShop">
            <el-option v-for="item in  shopList" :key="item.value"
                       :label="item.label" :value="item.value">
            </el-option>
          </el-select>
          <el-date-picker
            v-model="time"
            class="ml20"
            type="daterange"
            range-separator="至"
            start-placeholder="开始日期"
            end-placeholder="结束日期">
          </el-date-picker>
        </div>
        <el-button type="primary" class="sure" @click="search">筛选</el-button>
        <el-button type="info" class="sure" @click="clear">清空</el-button>
    </div>
    <div class="orderDetail">
      <span>订单总金额：{{orderAmount}}</span>
      <span>订单总笔数：{{orderNum}}</span>
    </div>
    <div class="tableBox">
      <el-table :data="tableData" style="width: 100%" border>
        <el-table-column prop="orderNumber" label="订单编号"></el-table-column>
        <el-table-column prop="shopId" label="店铺ID"></el-table-column>
        <el-table-column prop="shopName" label="店铺名称"></el-table-column>
        <el-table-column prop="spreadName" label="所属分公司"></el-table-column>
        <el-table-column prop="createTime" label="下单时间" width="160">
          <template slot-scope="scope">
            <span>{{ $formatDate(scope.row.createTime, 'yyyy-MM-dd HH:mm:ss') }}</span>
          </template>
        </el-table-column>
        <el-table-column prop="payMethod" label="支付方式" align="center">
          <template slot-scope="scope">
            <span v-if="scope.row.payMethod == 0">/</span>
            <span v-else-if="scope.row.payMethod == 1">线下支付</span>
            <span v-else-if="scope.row.payMethod == 2">线上支付</span>
            <span v-else-if="scope.row.payMethod == 3">钱包支付</span>
            <span v-else-if="scope.row.payMethod == 4">组合支付</span>
          </template>
        </el-table-column>
        <el-table-column prop="account" label="结算状态" align="center">
          <template slot-scope="scope">
            <span v-if="scope.row.payMethod == 1">--</span>
            <span v-else-if="scope.row.account == 1">已结算</span>
            <span v-else-if="scope.row.account == 2">待结算24小时</span>
            <span v-else-if="scope.row.account == 3">待结算48小时</span>
            <span v-else-if="scope.row.account == 4">待结算-立即入账</span>
          </template>
        </el-table-column>
        <el-table-column prop="orderType" label="订单类型"></el-table-column>
        <el-table-column prop="tableNumber" label="台位号"></el-table-column>
        <el-table-column prop="totalAmount" label="总计金额"></el-table-column>
        <el-table-column prop="payStatus" label="支付状态" align="center">
          <template slot-scope="scope">
            <span v-if="scope.row.payMethod == 0">/</span>
            <span v-else-if="scope.row.payStatus == 1">未支付</span>
            <span v-else-if="scope.row.payStatus == 2">已支付(订单完成)</span>
            <span v-else-if="scope.row.payStatus == 3">支付后退款</span>
          </template>
        </el-table-column>
        <el-table-column prop="shopFinance" label="商户收益" align="center">
          <template slot-scope="scope">
            <span>{{scope.row.payMethod == 1 ? '--' : scope.row.shopFinance}}</span>
          </template>
        </el-table-column>
        <el-table-column prop="spreadFinance" label="平台收益" align="center">
          <template slot-scope="scope">
            <span>{{scope.row.payMethod == 1 ? '--' : scope.row.spreadFinance}}</span>
          </template>
        </el-table-column>
        <el-table-column prop="paymentAmount" label="支付金额" align="center">
          <template slot-scope="scope">
            <span>{{scope.row.payMethod == 1 ? '--' : scope.row.paymentAmount}}</span>
          </template>
        </el-table-column>
        <el-table-column prop="goodCount" label="商品数量"></el-table-column>
      </el-table>
    </div>
    <el-pagination class="mt10" background :page-count="totalPage"
                   :current-page.sync="currentPage" @current-change="pageChange">
    </el-pagination>
  </div>
</template>
<script>
import {
  $sp_findEatInOrder, $sp_agentList, $sp_spreadList, $sp_shopInfoList, $sg_scanOrderStatic,
} from '@/api/order';

export default {
  data() {
    return {
      form: {
        orderNumber: '',
        spreadId: '',
        agentId: '',
        shopId: '',
        payStatus: '',
      },
      time: '',
      totalPage: 1,
      currentPage: 1,
      tableData: [],
      branchOfficeList: [],
      loading: false,
      agentList: [],
      shopList: [],
      payStatusList: [
        {
          value: 1,
          label: '未支付',
        },
        {
          value: 2,
          label: '已支付',
        },
        {
          value: 3,
          label: '支付后退款',
        },
      ],
      orderAmount: 0,
      orderNum: 0,
    };
  },
  created() {
    this.getTableData();
  },
  mounted() {
    this.getSpreadList();
    this.getAgentList();
    this.getShopList();
  },
  methods: {
    // 筛选
    search() {
      this.currentPage = 1;
      this.getTableData();
    },
    // 清空
    clear() {
      this.form = {
        orderNumber: '',
        spreadId: '',
        agentId: '',
        shopId: '',
        payStatus: '',
      };
      this.time = '';
      this.getTableData();
    },
    // 请求列表数据
    getTableData() {
      const params = {
        pageCurrent: this.currentPage,
        pageSize: 20,
        ...this.form,
      };
      if (this.time) {
        if (this.time[0].getFullYear() !== this.time[1].getFullYear() || this.time[0].getMonth() !== this.time[1].getMonth()) {
          this.$message.error('查询时间必须在同一个月内');
          return;
        }
        params.startTime = this.$formatDateByDate(this.time[0], 'yyyy-MM-dd');
        params.endTime = this.$formatDateByDate(this.time[1], 'yyyy-MM-dd');
      } else {
        params.startTime = '';
        params.endTime = '';
      }
      // 请求订单数据
      $sp_findEatInOrder(params).then((res) => {
        this.tableData = res.records;
        this.totalPage = res.pages;
      });
      // 请求订单汇总
      $sg_scanOrderStatic(params).then((res) => {
        this.orderAmount = res.orderAmount || 0;
        this.orderNum = res.orderNum || 0;
      });
    },
    // 搜索归属分公司
    async searchBranchOffice(query) {
      this.loading = true;
      if (query) {
        await this.getSpreadList(query);
      } else {
        await this.getSpreadList('');
      }
      this.loading = false;
    },
    // 请求归属分公司数据
    getSpreadList(platName = '') {
      const params = {
        platName,
      };
      this.branchOfficeList = [];
      return $sp_spreadList(params).then((res) => {
        const tempArr = [];
        res.forEach((item) => {
          const json = {
            value: item.spreadId,
            label: item.platName,
          };
          tempArr.push(json);
        });
        this.branchOfficeList = tempArr;
      });
    },
    // 选择归属分公司
    spreadIdChange() {
      this.form.agentId = '';
      this.form.shopId = '';
      this.getAgentList();
      this.getShopList();
    },
    // 搜索代理商
    async searchAgent(query) {
      this.loading = true;
      if (query) {
        await this.getAgentList(query);
      } else {
        await this.getAgentList('');
      }
      this.loading = false;
    },
    // 请求代理商列表
    getAgentList(agentName = '') {
      const params = {
        spreadId: this.form.spreadId,
        agentName,
      };
      this.agentList = [];
      return $sp_agentList(params).then((res) => {
        const tempArr = [];
        res.forEach((item) => {
          const json = {
            value: item.agentSid,
            label: item.agentName,
          };
          tempArr.push(json);
        });
        this.agentList = tempArr;
      });
    },
    // 选择代理商
    agentSidChange() {
      this.form.shopId = '';
      this.getShopList();
    },
    // 搜索商户
    async searchShop(query) {
      this.loading = true;
      if (query) {
        await this.getShopList(query);
      } else {
        await this.getShopList('');
      }
      this.loading = false;
    },
    // 请求商户列表
    getShopList(shopName = '') {
      const params = {
        spreadId: this.form.spreadId,
        agentSid: this.form.agentSid,
        shopName,
      };
      this.shopList = [];
      return $sp_shopInfoList(params).then((res) => {
        const tempArr = [];
        res.forEach((item) => {
          const json = {
            value: item.shopId,
            label: item.shopName,
          };
          tempArr.push(json);
        });
        this.shopList = tempArr;
      });
    },
    // 翻页
    pageChange() {
      this.getTableData();
    },
  },
};
</script>
<style lang="scss" scoped>
.eatInOrder{
  width: 100%;
  min-width: 1400px;
  height: 100%;
  margin: 0;
  padding: 40px 20px;
  box-sizing: border-box;
  background-color: #fff;
  .orderDetail{
    width: 100%;
    margin-top: 40px;
    padding:20px;
    box-sizing: border-box;
    background-color: #f0f0f0;
    font-size: 14px;
    color: #555;
    span:first-child{
      margin-right: 80px;
    }
  }
  .tableBox{
    width: 100%;
    margin-top: 40px;
  }
  .el-table{
    min-height: 580px;
    & > th {
      background-color: #EF3F46;
      color: #fff;
    }
  }
}
</style>

